import * as easings from "d3-ease";

const fade = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: ({ visibledelay = 150 }) => ({
      type: "tween",
      duration: 300,
      delay: visibledelay,
      ease: easings.easeSinOut,
    }),
  },
};

const fadeUp = {
  hidden: {
    opacity: 0,
    y: ({ offset = 20 }) => {
      return offset;
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: ({ visibledelay = 150 }) => ({
      type: "tween",
      duration: 300,
      delay: visibledelay,
      ease: easings.easeSinOut,
    }),
  },
};

const fadeSlide = {
  hidden: {
    opacity: 0,
    x: ({ offset = 10 }) => offset,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: ({ visibledelay = 150 }) => ({
      type: "tween",
      duration: 300,
      delay: visibledelay,
      ease: easings.easeSinOut,
    }),
  },
};

export { fade, fadeUp, fadeSlide };
