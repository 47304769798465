import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Text } from 'rebass';
import * as easings from 'd3-ease';
import { PoseableColumn } from 'components/poseable';
import posed from 'react-pose';
import { sectionHeading } from 'components/typography/sizes';

const Label = styled(Text)`
  display: block;
  media screen and (min-width:${props => props.theme.breakpoints[3]}px) {
    display: block;
  }
`;

const PosedColumn = posed(PoseableColumn)({
  hidden: {
    opacity: 0,
    x: -10
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'tween',
      duration: 300,
      ease: easings.easeSinOut
    }
  }
});

export default withTheme(
  ({ theme, children, num, label, color, width, ...props }) => {
    return (
      <PosedColumn width={width || [1, 1, 1, 1 / 12]}>
        <Text
          color={color || theme.asideLabelColor || theme.textColor}
          fontFamily="Calibre"
          fontWeight="bold"
          fontSize={sectionHeading}
          lineHeight={1.35}
        >
          {num && (
            <Label
              is="span"
              width={[`auto`, `auto`, `auto`, `100%`]}
              mr={`5px`}
            >
              {num}
            </Label>
          )}
          {label && (
            <Label
              is="span"
              width={[`auto`, `auto`, `auto`, `100%`]}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </Text>
      </PosedColumn>
    );
  }
);
