export const biggest = [66, 96, 124, 148, 180, 220];
export const big = [56, 72, 84, 90, 96, 120];
export const sectionHeading = [14, 14, 14, 14, 16];
export const smallHeading = [20, 24, 24, 24, 28];
export const formHeading = [18, 22, 24];
export const normal = [16, 18, 20];

const captionCss = `& a{ text-decoration:none; color:inherit }`;

export const caption = {
  size: [14, 16, 16],
  marginBelowColumn: [14, 8, 8, 8, 10],
  margin: [14, 16, 18, 20, 22],
  css: captionCss,
};
