import GlobalEmitter from "utils/GlobalEmitter";
import { PureComponent } from "react";
import events from "utils/events";

class BaseTemplate extends PureComponent {
  state = { ready: false };
  /**
   *
   *
   * @readonly
   * @memberof BaseTemplate
   * // whether or not to delay the page's ready state using a timeout
   */
  get usePageReadyTimeout() {
    return true;
  }

  onPageReady = () => {
    this.setState({ ready: true }, () => {
      GlobalEmitter.emit(events.pageReady);
    });
  };

  setPageReadyTimeout(delay = 750) {
    clearTimeout(this.pageReadyTimeout);
    this.pageReadyTimeout = setTimeout(this.onPageReady, delay);
  }

  componentWillUnmount() {
    clearTimeout(this.pageReadyTimeout);
  }

  componentDidMount() {
    if (this.usePageReadyTimeout) {
      this.setPageReadyTimeout();
    }
  }
}

export default BaseTemplate;
