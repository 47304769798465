import styled, { withTheme } from "styled-components";

import ButtonArrow from "components/icons/ButtonArrow";
import GatsbyLink from "gatsby-link";
import { PoseableText } from "components/poseable";
import React from "react";
import { fadeUp } from "components/poses";
import posed from "react-pose";

const StyledLink = styled(
  posed(PoseableText)({
    hoverable: true,
    focusable: true,
    ...fadeUp,
  })
)`
  position: relative;
  display: inline-block;
  text-decoration: none;
  overflow: visible;
  background: transparent;
  -webkit-appearance: none;
  border: none;
  :focus,
  :active {
    outline: none;
  }
  cursor: pointer;

  & > span {
    display: inline-block;
  }

  & svg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    :nth-child(2) {
      top: 0.5em;
      right: 0.5em;
    }
  }

  & a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
    position: relative;
    overflow: visible;

    :focus,
    :active {
      outline: none;
    }
  }
`;

export default withTheme(
  ({
    theme,
    url,
    anchor = null,
    label,
    color,
    children,
    visibledelay = 0,
    as,
    ...props
  }) => {
    if (as === `button` || as === `a`) {
      return (
        <StyledLink
          is={as}
          fontWeight="bold"
          fontFamily="Calibre"
          fontSize={[18, 24, 24, 24, 30]}
          color={color || theme.textColor}
          visibledelay={visibledelay}
          tabIndex={0}
          {...props}
        >
          <span tabIndex={-1}>{label}</span>
          <figure
            tabIndex={-1}
            style={{
              position: `absolute`,
              overflow: `hidden`,
              top: 0,
              right: `-1em`,
              width: `0.5em`,
              height: `0.5em`,
            }}
          >
            <ButtonArrow
              tabIndex={-1}
              fill={color || theme.textColor}
              width={`0.5em`}
              height={`0.5em`}
            />
          </figure>
        </StyledLink>
      );
    }
    return (
      <StyledLink
        fontWeight="bold"
        fontFamily="Calibre"
        fontSize={[18, 24, 24, 24, 30]}
        color={color || theme.textColor}
        visibledelay={visibledelay}
        as={as}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.which === 13) {
            e.target.querySelector("a").click();
          }
        }}
        // onClick={(e) => {
        //   console.log("clicked", e.target);
        //   e.target.querySelector("a").click();
        // }}
        {...props}
      >
        <GatsbyLink tabIndex={-1} to={`${url}${anchor ? `#${anchor}` : ``}`}>
          <span>{label}</span>
          <figure
            style={{
              position: `absolute`,
              overflow: `hidden`,
              top: 0,
              right: `-1em`,
              width: `0.5em`,
              height: `0.5em`,
            }}
          >
            <ButtonArrow
              fill={color || theme.textColor}
              width={`0.5em`}
              height={`0.5em`}
            />
          </figure>
        </GatsbyLink>
      </StyledLink>
    );
  }
);
