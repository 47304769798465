import React from 'react';

const NewLineToBr = str => {
  NewLineToBr.ID++;

  const id = NewLineToBr.ID;
  const aStr = str.split('\n');

  return (
    <React.Fragment>
      {aStr.map((piece, idx) => (
        <React.Fragment key={`newlinetobr-${id}-${idx}`}>
          {piece}
          {idx === aStr.length - 1 ? null : <br />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

NewLineToBr.ID = 0;

export default NewLineToBr;
