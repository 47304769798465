import React from 'react';
import posed from 'react-pose';
import * as easings from 'd3-ease';

const SVGContainer = posed.div({
  init: {
    x: `0`,
    y: `0`,
    transition: {
      type: 'tween',
      ease: easings.easeSinIn,
      duration: 200
    }
  },
  hover: {
    x: `150%`,
    y: `-150%`,
    applyAtEnd: { x: `0`, y: `0` },
    transition: {
      type: 'tween',
      ease: easings.easeSinOut,
      duration: 300
    }
  },
  focus: {
    x: `150%`,
    y: `-150%`,
    applyAtEnd: { x: `0`, y: `0` },
    transition: {
      type: 'tween',
      ease: easings.easeSinOut,
      duration: 300
    }
  }
});

const ButtonArrow = ({ title, fill, width, height, ...props }) => (
  <React.Fragment>
    <SVGContainer
      style={{
        position: `absolute`,
        width: `100%`,
        height: `100%`,
        top: 0,
        left: 0
      }}
      {...props}
    >
      <svg viewBox="0 0 16 16" width="100%" height="100%">
        <title>{title || 'arrow'}</title>
        <path
          fill={props.fill || `currentColor`}
          fillRule="evenodd"
          d="M1.072.07v2.493l9.873.008L.187 13.33l1.768 1.768L12.713 4.339l.008 9.874h2.493L15.213.07z"
        />
      </svg>
    </SVGContainer>
    <SVGContainer
      style={{
        position: `absolute`,
        width: `100%`,
        height: `100%`,
        top: `150%`,
        left: `-150%`
      }}
      {...props}
    >
      <svg viewBox="0 0 16 16" width="100%" height="100%">
        <title>{title || 'arrow'}</title>
        <path
          fill={props.fill || `currentColor`}
          fillRule="evenodd"
          d="M1.072.07v2.493l9.873.008L.187 13.33l1.768 1.768L12.713 4.339l.008 9.874h2.493L15.213.07z"
        />
      </svg>
    </SVGContainer>
  </React.Fragment>
);

export default ButtonArrow;
