import React from "react";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";

import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "components/BGGrid";

import BreakpointListener from "components/BreakpointListener";
import TextButtonWithArrow from "components/buttons/TextButtonWithArrow";
import { Column, Container, PosedSection, Row } from "components/layout";
import { PoseableColumn } from "components/poseable";
import { fadeUp } from "components/poses";
import Content from "components/typography/Content";
import SectionAside from "components/typography/SectionAside";
import { big } from "components/typography/sizes";
import Link from "gatsby-link";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import PropTypes from "prop-types";
import { Component } from "react";
import posed from "react-pose";
import { Text } from "rebass";
import styled, { withTheme } from "styled-components";
import events from "utils/events";
import newlineToBr from "utils/newlineToBr";
import { byUrl } from "utils/resolveLink";

const SocialLink = styled(Text)`
  will-change: color;
  transition: color 0.2s ease-in;
  display: block;
  margin-bottom: 1em;

  :hover,
  :focus {
    outline: none;
    transition: color 0.2s ease-out;
    color: ${(props) =>
      props.theme.footerHighlightColor || props.theme.footerTextColor};
  }
`;

const TextLink = styled(Text)`
  text-decoration: none;

  :hover,
  :focus {
    outline: none;
    text-decoration: underline;
  }
`;

const PosedColumn = posed(PoseableColumn)(fadeUp);

class Footer extends Component {
  static propTypes = {
    footerSmallHeading: PropTypes.string,
    footerHeading: PropTypes.string,
    footerLinkLabel: PropTypes.string,
    footerLink: PropTypes.string,
  };
  static defaultProps = {
    footerSmallHeading: null,
    footerHeading: null,
    footerLinkLabel: null,
    footerLink: null,
  };

  state = { mobile: false, rendered: false };

  componentDidMount() {
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
    this.revealTimeout = setTimeout(() => {
      this.setState({ rendered: true });
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.revealTimeout);
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ mobile: newSize < 2 });
  };

  render() {
    const { theme } = this.props;
    let {
      footerSmallHeading,
      footerHeading,
      footerLinkLabel,
      footerLink,
      globals,
    } = this.props;

    const {
      linkedinUrl,
      instagramUrl,
      twitterUrl,
      addressLine1,
      addressLine2,
      phoneNumber,
      infoEmail,
      ethicsReportUrl,
      privacyPolicyUrl,
      supplierCodeOfConductUrl,
      accessibilityPolicyUrl,
      aodaPlanUrl,
      gdprUrl,
    } = globals;

    const { mobile, rendered } = this.state;
    if (!footerSmallHeading) {
      footerSmallHeading = globals.footerSmallHeading;
    }

    if (!footerHeading) {
      footerHeading = globals.footerHeading;
    }

    if (!footerLinkLabel) {
      footerLinkLabel = globals.footerLinkLabel;
    }

    if (!footerLink) {
      footerLink = globals.footerLink;
    }
    return (
      <PosedSection is="footer" pt={[75, 100, 125, 150]} pb={0}>
        <Grid
          initialPose={rendered ? `enter` : `exit`}
          pose={rendered ? `enter` : `exit`}
          mobile={mobile}
          position={`absolute`}
          height={`100%`}
          bgColor={theme.footerBgColor || theme.fgColor}
          lineColor={theme.footerLineColor}
        />
        <Container>
          <Row>
            <SectionAside
              label={footerSmallHeading}
              color={theme.footerTextColor}
            />
            <Column width={[1, 1, 1, 10 / 12]}>
              <Content
                posed={true}
                visibledelay={200}
                mt={[0, 0, -1]}
                mb={[70, 80, 90, 100]}
                fontFamily={"Druk"}
                fontSize={big}
                lineHeight={`1em`}
                color={theme.footerTextColor}
              >
                {newlineToBr(footerHeading)}
              </Content>
            </Column>
            <Column
              ml={[`0px`, `0px`, `0px`, `${(1 / 12) * 100}%`]}
              width={[1, 1, 1, 1 / 2, 8 / 12]}
            >
              <TextButtonWithArrow
                visibledelay={300}
                color={theme.footerTextColor}
                label={footerLinkLabel}
                url={byUrl(footerLink)}
              />
            </Column>
            <PosedColumn width={[1, 1, 1, 3 / 12]} visibledelay={400}>
              <Row>
                <Column width={1 / 3}>
                  <SocialLink
                    is={OutboundLink}
                    fontSize={[16, 18]}
                    title={"Linkedin"}
                    href={linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    color={theme.footerTextColor}
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </SocialLink>
                  <SocialLink
                    is={OutboundLink}
                    fontSize={[16, 18]}
                    title={"Instagram"}
                    href={instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    color={theme.footerTextColor}
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </SocialLink>
                  <SocialLink
                    is={OutboundLink}
                    fontSize={[16, 18]}
                    title={"Twitter"}
                    href={twitterUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    color={theme.footerTextColor}
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </SocialLink>
                </Column>
                <Column width={2 / 3}>
                  <Text
                    fontFamily="Calibre"
                    fontSize={[16, 18]}
                    color={theme.footerTextColor}
                    lineHeight={1.38888888889}
                  >
                    {addressLine1}
                    <br />
                    {addressLine2}
                    <br />
                    <TextLink
                      is={OutboundLink}
                      color={theme.footerTextColor}
                      fontFamily="Calibre"
                      fontSize={[16, 18]}
                      lineHeight={1.38888888889}
                      href={`tel:${phoneNumber}`}
                      title="Call us"
                    >
                      {phoneNumber}
                    </TextLink>
                  </Text>
                  <TextLink
                    is={OutboundLink}
                    color={theme.footerTextColor}
                    fontFamily="Calibre"
                    fontSize={[16, 18]}
                    lineHeight={1.38888888889}
                    href={`mailto:${infoEmail}`}
                    title="Contact us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {infoEmail}
                  </TextLink>
                </Column>
              </Row>
            </PosedColumn>
            <PosedColumn
              visibledelay={500}
              width={[1, 1, 1, 10 / 12]}
              ml={[0, 0, 0, `${(1 / 12) * 100}%`]}
              mt={[50, 60, 70, 75]}
              mb={[25, 55, 75, 85]}
            >
              <TextLink
                is={OutboundLink}
                color={theme.footerTextColor}
                fontFamily="Calibre"
                fontSize={[16, 18]}
                lineHeight={1.38888888889}
                href={ethicsReportUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ethics Report
              </TextLink>
              <br />
              <TextLink
                is={Link}
                color={theme.footerTextColor}
                fontFamily="Calibre"
                fontSize={[16, 18]}
                lineHeight={1.38888888889}
                to={privacyPolicyUrl}
              >
                Privacy Policy
              </TextLink>
              <br />
              <TextLink
                is={OutboundLink}
                color={theme.footerTextColor}
                fontFamily="Calibre"
                fontSize={[16, 18]}
                lineHeight={1.38888888889}
                href={supplierCodeOfConductUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Supplier Code of Conduct
              </TextLink>
              <br />
              <TextLink
                is={OutboundLink}
                color={theme.footerTextColor}
                fontFamily="Calibre"
                fontSize={[16, 18]}
                lineHeight={1.38888888889}
                href={gdprUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                GDPR Policy
              </TextLink>
              <br />
              <TextLink
                is={OutboundLink}
                color={theme.footerTextColor}
                fontFamily="Calibre"
                fontSize={[16, 18]}
                lineHeight={1.38888888889}
                href={accessibilityPolicyUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Accessibility Policy
              </TextLink>
              <br />
              <TextLink
                is={OutboundLink}
                color={theme.footerTextColor}
                fontFamily="Calibre"
                fontSize={[16, 18]}
                lineHeight={1.38888888889}
                href={aodaPlanUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                AODA Multi-Year Plan
              </TextLink>
              <br />
              <TextLink
                as={`a`}
                color={theme.footerTextColor}
                fontFamily="Calibre"
                fontSize={[16, 18]}
                lineHeight={1.38888888889}
                href={`javascript:Didomi.preferences.show()`}
              >
                Consent choices
              </TextLink>
            </PosedColumn>
          </Row>
        </Container>
      </PosedSection>
    );
  }
}

export default withTheme(Footer);
