import React from "react";
import styled, { withTheme } from "styled-components";
import { normal } from "components/typography/sizes";
import { fadeUp } from "components/poses";
import posed from "react-pose";
import { PoseableText } from "components/poseable";

const defaultCss = `
position: relative;

${(props) =>
  props.vcenter === 1 &&
  `
display:flex;
flex-direction:column;
height:100%;
justify-content:center;
`}

p {
  margin-bottom: 1.8rem;
  line-height: 1.4;
}

h2,
h3,
h4,
h5,
h6 {
  margin-top: 1.8rem;
  margin-bottom: 1rem;
}

h2,
h3 {
  font-weight: bold;
}

h2 {
  font-family: 'Larish';
  font-weight: semibold;
  font-size: 1.5em;
}

h3 {
  font-size: 0.9em;
}

ul {
  list-style: none;
}

ul li::before {
  content: '\\2022';
  color: ${(props) => props.theme.headingColor};
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

& *:first-child {
  margin-top: 0;
}

& *:last-child {
  margin-bottom: 0;
}

& em{
  padding-right:4px;
}
`;

const StyledText = styled(PoseableText)`
  ${defaultCss}
  ${(props) => props.css}
`;

const StyledPosedText = styled(posed(PoseableText)(fadeUp))`
  ${defaultCss}
  ${(props) => props.css}
`;

export default withTheme(
  ({
    first,
    theme,
    children,
    hostRef,
    colorProp,
    fontSize,
    lineHeight,
    posed = false,
    visibledelay = 0,
    size,
    debug = false,
    ...props
  }) => {
    const TextComponent = posed ? StyledPosedText : StyledText;
    return (
      <TextComponent
        visibledelay={posed ? visibledelay : null}
        m={`0px`}
        mt={first ? [0, 0, `-0.1em`] : [0, 0, `-0.3em`]}
        is={`h2`}
        color={
          colorProp && theme.colors[colorProp]
            ? theme.colors[colorProp]
            : theme.textColor
        }
        fontFamily="Calibre"
        fontSize={
          fontSize
            ? fontSize
            : size
            ? size === "small"
              ? [18, 24, 30]
              : [30, 36, 36, 42, 50, 60]
            : normal
        }
        lineHeight={lineHeight || 1.4}
        {...props}
      >
        {children}
      </TextComponent>
    );
  }
);
